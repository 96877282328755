<template>
    <div>
        <template>
            <template v-if="$wait.is('fetch companies')">
                <v-skeleton-loader
                    v-for="n in 3"
                    :key="n"
                    loading
                    :type="'card-heading'"
                    tile
                    class="px-0 mx-0 my-0 mt-5 block"
                    width="100%"
                ></v-skeleton-loader>
            </template>
            <template v-else>
                <v-list
                    subheader
                    tile
                    dense
                >
                    <v-list-item-group v-model="selected" color="primary">
                        <v-list-item
                            :class="companyClassObject('all')"
                            class="mb-3"
                            :value="{id: 'all'}"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-t="'all'" />
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            v-for="company in companies"
                            :key="company.id"
                            :class="companyClassObject(company.id)"
                            class="mb-3"
                            :value="company"
                        >
                            <template v-slot:default>
                                <v-list-item-icon v-if="company.countryCode">
                                    <v-avatar size="24" class="ml-0 float-left">
                                        <img
                                            :src="`${$publicPath}images/countries/${company.countryCode.toLowerCase()}.svg`"
                                            :alt="company.countryName">
                                    </v-avatar>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-tooltip
                                        bottom
                                        :nudge-left="100"
                                        :z-index="100"
                                        :max-width="300"
                                        color="primary"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-list-item-title v-on="on">{{ company.title }} <span class="caption">({{ company.processingActivityCount }})</span></v-list-item-title>
                                        </template>
                                        <template v-slot:default>
                                            <span
                                                v-text="company.title"
                                                class="font-weight-bold"
                                            /><br />
                                            <span v-text="getCompanyInformation(company)" />
                                        </template>
                                    </v-tooltip>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    name: 'FilterResultCompanies',
    props: {
        companies: {
            type: Array,
            default: () => []
        },
        selectedDomain: {
            type: Object,
            default: null
        },
        selectedCompany: {
            type: Object,
            default: null
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/List/en.json'),
            de: require('@/locales/vvt/List/de.json'),
        },
    },
    computed: {
        selected: {
            get () {
                return this.selectedCompany;
            },
            set(company) {
                if(company && (!this.selectedCompany || !Object.prototype.hasOwnProperty.call(this.selectedCompany, 'id') || company.id !== this.selectedCompany.id)) {
                    this.$emit('select-company', company);
                }
            }
        }
    },
    methods: {
        isActive(companyId) {
            return (
                this.selectedCompany && this.selectedCompany.id === companyId
            );
        },
        companyClassObject(companyId) {
            return {
                'v-list-item--active': this.isActive(companyId),
            };
        },
        getCompanyInformation(company) {
            return company.city
                ? company.city + ', ' + company.countryName
                : company.countryName;
        },
    },
};
</script>
